import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Printer from './printer/Printer'
import api from '@/global-components/api'
import { PrinterType } from '@/global-components/types'
import { useParams } from "react-router-dom";
import { Button } from "@/global-components/components/ui/button"
import { Input } from '@/global-components/components/ui/input'
import { PlusCircle, MinusCircle } from 'lucide-react'

import './Printers.scss'

const Printers = () => {
  const { factoryId } = useParams();
  const [printers, setPrinters] = useState<PrinterType[]>([]);
  const printersQuery = useQuery(api.printers.queries.GET_ALL_PRINTERS_WITH_SCHEDULE, {
    variables: {
      factoryId: factoryId ? factoryId : 1
    },
    pollInterval: 1000,
  });

  let [newPrinterName, setNewPrinterName] = useState<string>('')
  let [newPrinterUuid, setNewPrinterUuid] = useState<string>('')
  let [newPrinterUiVisible, setNewPrinterUiVisible] = useState<Boolean>(false)
  const [createPrinter] = useMutation(api.printers.mutations.CREATE_PRINTER)
  const createNewPrinter = () => {
    createPrinter({variables: {name: newPrinterName, uuid: newPrinterUuid, factoryId: factoryId}})
      .then((result: any) => {
        console.log(result)
        setNewPrinterUiVisible(false)
      })
      .catch(err => {
        console.log(err)
      })
  }  

  useEffect(() => {
    if (printersQuery.data?.printersInLocation) {
      // console.log(printersQuery.data?.printersInLocation);
      const printersInLoaction: PrinterType[] = [...printersQuery.data?.printersInLocation || []];
      printersInLoaction?.sort((a, b) => (a.printerId > b.printerId) ? 1 : -1);
      setPrinters(printersInLoaction);
    }
  }
  ,[printersQuery.data, printersQuery.loading, printersQuery])

  return (
    <div className='printers grid gap-2 grid-cols-7 pt-4 pl-4 pr-4 pb-2'>
      {printers.map((printer: PrinterType) => 
        <Printer key={printer.printerId} props={printer}/>
      )}
      <div className='add-printer'>
        <Button variant="ghost" onClick={() => setNewPrinterUiVisible(!newPrinterUiVisible)}>
          {newPrinterUiVisible ? 
            <MinusCircle className='icon w-4 h-4'/>
            :
            <PlusCircle className='icon w-4 h-4'/>
          }
        </Button>
        <div className={`printer relative add-printer-inputs ${newPrinterUiVisible ? '' : 'invisible'}`}>
          <div className='inputs'>
            <Input type='text' name='printer-name' placeholder="Printer Name" value={newPrinterName} onChange={(e)=>setNewPrinterName(e.target.value)} />
            <Input type='text' name='printer-uuid' placeholder="Printer UUID" value={newPrinterUuid} onChange={(e)=>setNewPrinterUuid(e.target.value)} />
          </div>
          <Button variant="bwconfirm" disabled={newPrinterName === '' || newPrinterUuid  === '' } onClick={() => createNewPrinter()}>Add new printer</Button>
        </div>
      </div>
    </div>
  )
}

export default Printers;