import React, { useState, ChangeEvent, useEffect } from 'react';
import { Button } from "@/global-components/components/ui/button"
import { useApolloClient, useMutation } from '@apollo/client';
import { Input } from '@/global-components/components/ui/input'
import { Spin } from "react-cssfx-loading";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@/global-components/components/ui/dialog"
import { Check, PlusCircle } from "lucide-react";
import api from '@/global-components/api'

const UploadMacro = (props: any) => {
  const [open, setOpen] = useState(false)
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const [macroUploadCommand, setMacroUploadCommand] = useState<string>('');

  const [createNewMacro] = useMutation(api.macros.mutations.CREATE_NEW_MACRO, {
    onCompleted: data => {
      console.log(data)
    },
    onError: err => {
      console.log(err);
    }
  })

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(e.target.files || []);
    const updatedFiles = [...newFiles];
    setFiles(updatedFiles);
  }

  const uploadMacro = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log('trying to upload')
    console.log('command ', macroUploadCommand)
    console.log('file ', files[0])


    createNewMacro({variables: {command: macroUploadCommand, macro: files[0]}})
      .then(() => {
        setOpen(false);
      })
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {props.fromCard ?
          <div className='submit-product-card flex flex-col text-bw-grey hover:text-bw-green justify-center items-center'>
            <PlusCircle className='icon w-8 h-8'/>
          </div>
          : <Button variant="ghost" size='sm' className='text-xs'>Upload new Macro</Button>
        }
      </DialogTrigger>
      <DialogContent className='max-w-2xl'>
        <DialogHeader>
          <DialogTitle>Upload new Macro - Development functionality</DialogTitle> 
        </DialogHeader>
        <div className='text-sm text-bw-grey-darker max-w-[80%] mb-1'>
          Here you can upload macros for the printer. Currently, this is a DEV function, as you can't trigger new macro's yet. We will implement that functionality in the future. Please only use this if you know what you're doing.
        </div>
        <form onSubmit={uploadMacro} className='flex flex-col gap-2'>
          <Input type='text' name='command' placeholder='Command Name' required id='command' value={macroUploadCommand} onChange={(event) => setMacroUploadCommand(event.target.value)}/>
          <Input id="files" type="file" multiple onChange={handleFile} placeholder='*.gcode File' accept=".gcode" className="border-dashed" required/>
          <DialogFooter className='mt-4'>
            <Button disabled={uploading} variant="bwprimary" type="submit">{uploading ? <Spin className="inline-spin h-4 w-4" color="#36463D" width="20px" height="20px" duration="0.3s" /> : 'Submit'}</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default UploadMacro;