import React, {useState, ChangeEvent} from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Button } from "@/global-components/components/ui/button"
import { Input } from '@/global-components/components/ui/input'
import BwSymbol from '@/global-components/brand-assets/BwSymbol';

import { useAuth } from '../../context/auth';
import api from '@/global-components/api'
import { useToast } from "@/global-components/components/ui/use-toast";
export type LoginProps = {
}

const Login = (props: LoginProps) =>  {
  const navigate = useNavigate();
  const { toast } = useToast()
  const { setLoggedIn, setUser } = useAuth();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [login, { loading, error }] = useMutation(api.user.mutations.LOGIN, {
    onCompleted: data => {
      if (data?.tokenAuth?.token) {
        if (data.tokenAuth.user.isStaff) {
          setLoggedIn(true);
          setUser({
            userId: data?.tokenAuth.user.userId,
            username: data?.tokenAuth.user.username,
            isStaff: data?.tokenAuth.user.isStaff,
            firstName: data?.tokenAuth.user.firstName,
            lastName: data?.tokenAuth.user.lastName,
            email: data?.tokenAuth.user.email,
            userPreferences: data?.tokenAuth.user.userpreferencesmodelSet[0]
          })

          api.user.local.setToken(data.tokenAuth.token);
          navigate('/');
        } else {
          toast({
            title: "No Permission",
            description: "We're really sorry, but you don't have permission to access the factory.",
            variant: 'destructive',
            duration: 2000
          })
        }
      } else {
        console.log('Login unsuccessful.');
        toast({
          title: "Login unsuccessful",
          description: "We're really sorry, but either your email address or password is incorrect.",
          variant: 'destructive',
          duration: 2000
        })
      }
    },
    onError: err => console.error(err),
  });

  const loginWithFormData = () => {
    login({variables: {email: email, password: password}})
  }

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;
  
  return (
    <div className='view login flex flex-col items-center justify-center h-full'>
      <BwSymbol className='w-16 h-16 mb-4 mt-8' />  
      <h1 className='text-center  font-bold'>Factory Manager</h1>
      <div className='text-bw-green mb-16 text-center text-sm'>Beta</div>
      <div className='login-container max-w-[250px]'>
        <form onSubmit={loginWithFormData} className='mb-16 flex flex-col gap-1'>
          <Input type='email' name='email' placeholder="Email" value={email} onChange={handleEmailChange} />
          <Input type='password' name='password' placeholder="Password" value={password} onChange={handlePasswordChange} />
          <Button variant="bwprimary" type="submit">Login</Button>

        </form>
        <div className='flex flex-col gap-2'>
          <Button variant="link" size='sm' className='hidden' onClick={() => login({ variables: {email: 'marci@batch.works', password: 'testing1234!'}})}>Login Finji - superuser</Button>
        </div>
      </div>
    </div>
  )
}

export default Login; 